import { Variant } from '@blissbook/lib/expression'
import { getFirstName, getLastName } from '@blissbook/lib/name'
import { formatEmail, isValidEmailAddress } from '@blissbook/lib/sanitize'
import { immerable } from 'immer'
import compact from 'lodash/compact'
import find from 'lodash/find'
import first from 'lodash/first'
import last from 'lodash/last'
import moment from 'moment'
import Organization from './organization'

import { fromJSON, mapJSONValues } from './util/json'

export class Person {
  id: number
  email?: string
  employeeId?: string
  fullName: string
  groupIds: number[]
  adminRoleId?: string

  static get ATTRIBUTES() {
    return {
      createdAt: fromJSON.date,
      updatedAt: fromJSON.date,
      lastHiredOn: (v: any) => moment(v, 'YYYY-MM-DD').toDate(),
      organization: Organization.fromJSON,
    }
  }

  // Map attributes
  static mapJSON(json: any) {
    return mapJSONValues(json, Person.ATTRIBUTES)
  }

  // Create a person from JSON
  static fromJSON(json: any) {
    return json && Object.assign(new Person(), Person.mapJSON(json))
  }

  // Create a person from text
  static fromText(text: string) {
    const json = parseNameAndEmail(text)
    return Person.fromJSON(json)
  }

  // Variant
  static get variantType() {
    return 'person' as const
  }
  get displayName() {
    return this.fullName || this.email
  }
  get uid() {
    return this.toVariant().uid
  }
  toVariant() {
    return new Variant(Person.variantType, this.id)
  }

  getGroups(allGroups: IGroup[]) {
    const groups = this.groupIds.map((id) => find(allGroups, { id }))
    return compact(groups)
  }

  get url() {
    return `/people/${this.id}`
  }

  get firstName() {
    return getFirstName(this.fullName)
  }

  get lastName() {
    return getLastName(this.fullName)
  }

  get loginId() {
    return this.email || this.employeeId
  }
}

// @ts-ignore: immerable
Person[immerable] = true

// Name & Email ---------------------------------------------------------------

const nameEmailRegEx = /(.+),\s*(.+)/i
const nameEmailRegEx2 = /(.+)\s*<(.+)>/i

// Parse out the name/email from this text
export const parseNameAndEmail = (text: string) => {
  const json: { email?: string; fullName?: string } = {}
  const params = text.match(nameEmailRegEx) || text.match(nameEmailRegEx2)
  if (params) {
    // Name & Email
    json.fullName = params[1].trim()
    json.email = params[2].trim()
  } else if (isValidEmailAddress(text)) {
    // Email
    json.email = text
    json.fullName = ''
  } else {
    // Give up, name
    json.fullName = text
    json.email = ''
  }

  // Ensure the email is lowercase
  json.email = formatEmail(json.email)
  return json
}

export const getInitials = (fullName: string) => {
  if (!fullName) return
  let names = fullName.split(' ')
  if (names.length > 2) names = [first(names), last(names)]
  return names.map((name) => name[0])
}
